import React from "react";
import { BoxContainer, PatientsCard, Tabs } from "../../components";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { TITLE_NAME } from "../../config/config";
import ImageExamsHistory from "./ImageExamsHistory";
import EletronicDocumentsHistory from "./EletronicDocumentsHistory";
import LaboratorialExams from "./LaboratorialExams";
import { useAttendaces } from "../../service";
import { useQuery } from "react-query";
import useNotifier from "../../hooks/useNotifier";

export default function Exams(props) {
  const params = useParams();
  const notify = useNotifier();
  const { getAttendanceById } = useAttendaces();

  const attendanceId = props.attendanceId ? props.attendanceId : params.id;

  const attendanceQuery = useQuery(
    ["attendance", attendanceId],
    () => getAttendanceById(attendanceId),
    {
      onError: (err) => {
        notify(err.message, "error");
      },
      enabled: !!attendanceId,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const tabs = [
    {
      label: "Exames de Imagem",
      content: (
        <ImageExamsHistory
          patientData={
            attendanceQuery.data?.patient ? attendanceQuery.data.patient : null
          }
        />
      ),
    },
    {
      label: "Documentos de Prontuário",
      content: (
        <EletronicDocumentsHistory
          patientData={
            attendanceQuery.data?.patient ? attendanceQuery.data.patient : null
          }
        />
      ),
    },
    {
      label: "Exames Laboratoriais",
      content: (
        <LaboratorialExams
          patient={
            attendanceQuery.data?.patient ? attendanceQuery.data.patient : null
          }
        />
      ),
    },
    {
      label: "Visualizador Clinico - eSaúde",
      content: (
        <></>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Exames</title>
      </Helmet>
      <BoxContainer title="Exames" hideBackButton={props.hideBackButton}>
        <PatientsCard
          patient={attendanceQuery.data}
          key={"pattientCard"}
          onDialog={true}
          hideOptions
        />
        <div style={{ marginTop: 10 }} />
        <Tabs tabs={tabs} />
      </BoxContainer>
    </>
  );
}
