import React from "react";
import { Box, TablePagination } from "@mui/material";
import TableFilter from "../../components/Table/TableFilter";
import useNotifier from "../../hooks/useNotifier";
import { Dialog, DialogMedium, Loading } from "../../helper";
import { addHours, format, parseISO } from "date-fns";
import { useExamIntegrations } from "../../service";
import { useQuery } from "react-query";
import ViewedDocumentHistory from "./ViewedDocumentHistory";
import PreviewIcon from "@mui/icons-material/Preview";

export default function EletronicDocumentsHistory({ patientData }) {
  const [filter, setFilter] = React.useState();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [exams, setExams] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [document, setDocument] = React.useState(null);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [queryData, setQueryData] = React.useState(null);

  const notify = useNotifier();
  const { getEletronicDocumentsByPatient, viewedDocumentHistoryGenerate } =
    useExamIntegrations();

  const viewedDocumentHistoryQuery = useQuery(
    ["documentHistory", queryData],
    () => viewedDocumentHistoryGenerate(queryData),
    {
      onSuccess: (data) => {
        setOpen(true);
        setDocument({ ...document, base64: data.url });
      },
      onError: (err) => notify(err.message, "error"),
      onSettled: () => {
        setQueryData(null);
      },
      enabled: !!queryData,
    }
  );
  const tableColumns = [
    {
      name: "Documento Clínico",
      field: "documentDescribe",
      type: "text",
    },
    {
      name: "Empresa",
      field: "multiCompanyDescribe",
      type: "text",
    },
    {
      name: "Data/Hora",
      field: "dateDocument",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy HH:mm") : "Não informado",
      type: "date",
    },
  ];

  const customFilters = [
    {
      name: "Data inicio",
      field: "initial_date",
      type: "date",
    },
    {
      name: "Data fim",
      field: "final_date",
      type: "date",
    },
  ];

  const eletronicDocumentsQuery = useQuery(
    ["eletronic-documents", filter, page, limit],
    () =>
      getEletronicDocumentsByPatient({
        id: patientData.id,
        params: { ...filter, page: page + 1, limit: limit },
      }),
    {
      retry: false,
      enabled: !!patientData,

      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCount(data.totalItems);
        setExams(data.items);
      },
      onError: (err) => {
        notify(err.message, "error");
      },
    }
  );

  function handleFilter(filter) {
    setPage(0);
    setFilter(filter);
  }

  function handleClose() {
    setOpen(false);
    setDocument(null);
  }

  return (
    <Box>
      {(viewedDocumentHistoryQuery.isFetching ||
        eletronicDocumentsQuery.isFetching) && (
        <Loading
          backgroundColor={"rgba(255,255,255,0.8)"}
          maxWidth={"100%"}
          message={
            viewedDocumentHistoryQuery.isFetching
              ? "Solicitando dados ..."
              : "Sincronizando dados ..."
          }
        />
      )}
      <Dialog
        title={`${document?.documentDescribe} - ${
          document?.dateDocument
            ? format(parseISO(document?.dateDocument), "dd/MM/yyyy")
            : ""
        }`}
        open={open}
        handleClose={handleClose}
      >
        <>
          <iframe
            width="100%"
            height="95%"
            title={document?.documentDescribe}
            src={`data:application/pdf;base64,${document?.base64}`}
            style={{ marginTop: -80 }}
          />
        </>
      </Dialog>
      <DialogMedium
        title={`Histórico de visualização - ${document?.documentDescribe}`}
        open={openHistory}
        handleClose={() => setOpenHistory(false)}
        maxWidth={"lg"}
      >
        <ViewedDocumentHistory
          document={document}
          documentType={"MV_ELECTRONIC_DOC"}
        />
      </DialogMedium>

      <Box>
        <TableFilter
          data={exams}
          columns={tableColumns}
          loading={eletronicDocumentsQuery.isLoading}
          emptyMessage={eletronicDocumentsQuery.error?.message}
          othersFilter={customFilters}
          actions
          actionUniqueIdentifier={"url"}
          actionsTypes={["view", "response"]}
          handleFilter={handleFilter}
          actionHandleView={(_, document) => {
            setDocument(document);
            setOpenHistory(true);
          }}
          disableActions={() => {
            if (viewedDocumentHistoryQuery.isFetching) {
              return true;
            }
          }}
          actionHandleResponse={(_, document) => {
            setQueryData({
              document_id: document.registerEditorId.toString(),
              type: "MV_ELECTRONIC_DOC",
              patient_id: patientData.id,
              date: format(
                addHours(parseISO(document.dateDocument), 3),
                "yyyy-MM-dd"
              ),
            });
            setDocument(document);
          }}
          customizeActions={{
            view: {
              title: "Histórico de Visualizações",
              icon: <PreviewIcon />,
            },
            response: {
              title: "Visualizar Documento",
            },
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
