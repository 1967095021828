import { DoneAll, MedicationRounded, PrintRounded } from "@mui/icons-material";
import React from "react";
import TableFilter from "../../../components/Table/TableFilter/Table";
import { drugAdmininstrations } from "../../../config/standardSelects";
import { format, parseISO } from "date-fns";
import { DialogMedium } from "../../../helper";
import DoubleCheckEvolution from "../../SOAP/Prescription/DoubleCheckEvolution";
import { Box } from "@mui/material";
import { groupPrescriptionCollection, openURL } from "../../../utils";
import {
  drugOnSitePrescriptionPDF,
  drugPrescriptionPDF,
  specialPrescriptionPDF,
} from "../../../pdfModels";

const pdfTypes = {
  medications_on_site: drugOnSitePrescriptionPDF,
  medications: drugPrescriptionPDF,
  special_medications: specialPrescriptionPDF,
};

export default function MedicationTable({
  prescription,
  check,
  type,
  activeActions,
  disableActions,
}) {
  const [toOpenDoubleCheck, setToOpenDoubleCheck] = React.useState(false);

  function handlePrint(data) {
    const firstItem = data[0];
    const isSpecial = firstItem.special;
    let documentType = type;

    if (isSpecial) {
      documentType = "special_medications";
    }

    const signedDocument = prescription.signed_documents.find((signedDocument) => {
      return signedDocument.type === documentType && signedDocument.tab_id === firstItem.tab_id;
    });

    if (signedDocument?.result) {
      return openURL(signedDocument.result);
    }

    const documentData = {
      createdAt: prescription.createdAt,
      employee: {
        ...prescription.employee_specialty?.employee,
        specialty: prescription.employee_specialty?.specialty,
      },
      company: prescription.attendance.company,
    };

    const pdf = pdfTypes[documentType](data, prescription.attendance.patient, documentData);
    pdf.open();
  }

  const columns = [
    { name: "Medicamento", field: "name" },
    { name: "Quantidade", field: "quantity" },
    { name: "Apresentação", field: "presentation" },
    { name: "Posologia", field: "posology" },
    {
      name: "Inicio",
      field: "initial_date",
      active: type !== "medications_on_site",
    },
    {
      name: "Conclusão",
      field: "final_date",
      condition: "!row.continuous_use",
      otherwise: () => "Uso contínuo",
      active: type !== "medications_on_site",
    },
    {
      name: "Suspenso",
      field: "suspended",
      use: (value) => (value ? "Sim" : "Não"),
    },
    {
      name: "Tp. Aplicação",
      field: "procedure_id",
      use: (value) =>
        drugAdmininstrations.filter((o) => o.procedure_id === value)?.[0]?.describe ||
        "Não informado.",
    },
    { name: "Observação", field: "notes" },
    {
      name: "Profissional",
      field: "employee_specialty",
      use: (value) => (value?.employee?.name ? value?.employee?.name : "-"),
    },
  ];

  const assistedColumns = [
    {
      name: "Data da Administração",
      field: "date",
      use: (value) => format(parseISO(value), "dd/MM/yyyy 'às' HH:mm"),
    },
    {
      name: "Administrado",
      field: "administration",
      use: (value) => (value ? "Sim" : "Não"),
    },
    { name: "Administrado Por", field: "employee.name" },
    { name: "Local", field: "company.name" },
  ];

  const groupedMedications = [];

  prescription[type].forEach((exam) => {
    if (exam.tab_id) {
      groupPrescriptionCollection(groupedMedications, exam);
    } else {
      groupedMedications.push([exam]);
    }
  });

  return (
    <>
      <DialogMedium
        title={"Dupla checagem"}
        open={!!toOpenDoubleCheck}
        fullWidth
        maxWidth={"md"}
        handleClose={() => setToOpenDoubleCheck(false)}
      >
        <DoubleCheckEvolution medicationOnSiteID={toOpenDoubleCheck} />
      </DialogMedium>
      <Box display="flex" flexDirection="column" gap="12px">
        {groupedMedications.map((group) => {
          const headerActions = [
            {
              title: "Imprimir",
              icon: <PrintRounded />,
              handler: () => handlePrint(group),
            },
          ];

          return (
            <TableFilter
              key={group[0].id}
              data={group}
              columns={
                type !== "assisted"
                  ? columns.filter(
                      (column) => column.active === undefined || column.active === true
                    )
                  : assistedColumns
              }
              actions={type !== "assisted" && activeActions}
              actionsTypes={
                type === "medications_on_site"
                  ? ["finish", "delete", "response"]
                  : ["finish", "delete"]
              }
              headerActions={headerActions}
              customizeActions={
                type === "medications_on_site"
                  ? {
                      finish: {
                        title: "Administrado",
                        icon: <MedicationRounded />,
                        verb: "marcar como Administrado",
                      },
                      delete: {
                        title: "Não Administrado",
                        icon: <MedicationRounded />,
                        verb: "marcar como Não Administrado",
                      },
                      response: {
                        icon: <DoneAll />,
                        title: "Dupla checagem",
                      },
                    }
                  : {
                      delete: {
                        title: "Não Dispensado",
                        verb: "marcar como Não Dispensado.",
                      },
                      finish: {
                        title: "Dispensado",
                        verb: "marcar como Dispensado.",
                      },
                    }
              }
              rowStatus={(item) =>
                item.dispensed === true || item.done === true
                  ? "active"
                  : item.dispensed === false ||
                    item.done === false ||
                    (type === "assisted" && !item?.administration)
                  ? "disabled"
                  : ""
              }
              activeRows={(item) => item.dispensed === true || item.done === true}
              disableActions={(item, action) => {
                if (disableActions instanceof Function) {
                  const result = disableActions(item, action);

                  if (result) return result;
                }

                return !(item.dispensed === null || item.done === null);
              }}
              actionHandleResponse={(_, item) => {
                setToOpenDoubleCheck(item.id);
              }}
              actionHandleFinish={check}
              actionHandleDelete={(_, item, toSend) => check(_, item, toSend, false)}
            />
          );
        })}
      </Box>
    </>
  );
}
